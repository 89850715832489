@import '@/style/index.scss';

.checkbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include font(body);

    & [data-role='checkbox-checkbox'] {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.3em;
        height: 1.3em;
        border: 2px solid color(grey, 5);
        box-sizing: border-box;
        position: relative;
        outline: none;
        flex-shrink: 0;
        flex-grow: 0;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: color(primary, 3);
            opacity: 0;
            position: absolute;
            transition: opacity 200ms $ease-in, transform 300ms $ease-out-back;
        }

        &::before {
            content: '';
            display: block;
            width: 50%;
            height: 50%;
            background-color: color(primary, 6);
            opacity: 0;
            position: absolute;
            transform: scale(0);
        }
    }

    & [data-role='checkbox-label'] {
        margin-left: 0.3em;
        flex-shrink: 1;
        flex-grow: 1;
        overflow: hidden;
    }
    & [data-role='checkbox-label-text'] {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &[aria-checked='true'] {
        & [data-role='checkbox-checkbox'] {
            border-color: color(primary, 6);
            background-color: color(primary, 2);

            &::before {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    &[aria-disabled='true'] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:not([aria-disabled='true']) {
        & [data-role='checkbox-checkbox'] {
            &:hover,
            &:focus {
                &::after {
                    opacity: 0.3;
                    transform: scale(1.5);
                }
            }

            &:active {
                &::after {
                    opacity: 0.5;
                    transform: scale(1.8);
                }
            }
        }
    }
}

.group {
    display: flex;
    flex-wrap: wrap;

    & [data-role='checkbox'] {
        margin: $gap / 4;
    }
}
