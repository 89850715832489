@import '@/style/index.scss';

.record {
    & [data-role='popup'] {
        max-width: screen-boundary(sm-md);
    }
}

.classify {
    @include font(subhead);
    margin-left: $gap / 2;
    color: color(grey, 3);
}

.amount {
    @include font(title-1);
    margin-top: $gap;
    margin-bottom: $gap / 4;
    word-break: break-all;
}

.currency {
    @include font(callout);
    color: color(grey, 4);
}

.timezone {
    margin-left: 0.5em;
}

.content {
    padding: $gap / 2;
    color: color(grey, 5);
}

.toolbar {
    padding-top: $gap * 2;
    position: sticky;
    bottom: 0;
    background-image: linear-gradient(
        to bottom,
        rgba(color(white, 5), 0),
        rgba(color(white, 5), 1) $gap * 2
    );
}
