@import '@/style/index.scss';

.grid {
    display: flex;
    box-sizing: border-box;
    margin: 0;

    &[data-container='true'] {
        width: 100%;
    }

    /* gap */
    @each $size in 0, 1, 2, 3, 4, 5, 6 {
        $_gap: $size * $gap * 0.25 / 2;
        &[data-gap='#{$size}'] {
            padding: $_gap;
            & > .grid {
                padding: $_gap;
                &[data-gap] {
                    padding: 0;
                }
            }
        }
    }

    /* direction */
    @each $var in row, row-reverse, column, column-reverse {
        &[data-direction='#{$var}'] {
            flex-direction: $var;
        }
    }

    /* wrap */
    @each $var in nowrap, wrap, wrap-reverse {
        &[data-wrap='#{$var}'] {
            flex-wrap: $var;
        }
    }

    /* justify */
    @each $var in flex-start, flex-end, center, space-between, space-around {
        &[data-justify='#{$var}'] {
            justify-content: $var;
        }
    }

    /* align-items */
    @each $var in flex-start, flex-end, center, baseline, stretch {
        &[data-align-items='#{$var}'] {
            align-items: $var;
        }
    }

    /* align-content */
    @each $var in flex-start, flex-end, center, space-between, space-around,
        stretch
    {
        &[data-align-content='#{$var}'] {
            align-content: $var;
        }
    }

    /* sm md lg xl */
    @each $size in sm, md, lg, xl {
        @media #{screen-media-min($size)} {
            &[data-#{$size}='auto'] {
                max-width: none;
                flex-basis: auto;
                flex-grow: 0;
                flex-shrink: 0;
            }
            &[data-#{$size}='true'] {
                width: 0;
                max-width: 100%;
                flex-basis: 0;
                flex-grow: 1;
            }
            @each $grid in 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 {
                $width: ($grid / 12) * 100%;
                &[data-#{$size}='#{$grid}'] {
                    width: $width;
                    flex-basis: $width;
                    flex-grow: 0;
                }
            }
        }
    }
}