@import '@/style/index.scss';

.type-group {
    display: flex;
    width: 100%;
    justify-content: center;
    & > [data-role='button'] {
        width: rem(80);
        max-width: 30%;
    }
}

.amount-screen {
    text-align: right;
}

.classify {
    width: 100%;
    overflow: auto;
    height: rem(88) * 2.2;
    margin-top: $gap;
    -webkit-overflow-scrolling: touch;
    border: 1px dashed color(grey, 5);
    border-radius: $border-radius;
    & [data-role='button'] {
        display: block;
        float: left;
        width: 20%;
        height: rem(88);
        box-sizing: border-box;
        border: 1px solid transparent;

        &[data-active='true'] {
            background-color: color(grey, 1);
            border-color: color(grey, 3);
            border-radius: $border-radius;
            & .text {
                color: color(grey, 7);
            }
        }
    }
}

.classify__icon {
    margin: 0 auto;
    font-size: rem(25);
}

.classify__text {
    @include font(caption-1);
    margin-top: rem(4);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    color: color(grey, 5);
}

.member-placeholder {
    @include font(callout);
    color: color(grey, 5);
}
