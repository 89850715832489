@import '@/style/index.scss';

/* 自适应 尺寸控制 */
html {
    /* mobile */
    @media #{screen-media(sm)} {
        font-size: calc(100vw / 7.5);
    }

    /* tablet */
    @media #{screen-media(md)} {
        font-size: calc(100vw / 28.4 + 31.69px);
    }

    /* desktop */
    font-size: 50px;
}

body {
    font-family: $font-family;
    font-size: rem(17);
    line-height: 1.5;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
}
a {
    text-decoration: none;
}