@import '@/style/index.scss';

.navbar {
    background-color: color(white);
    box-shadow: shadow(3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: rem(44);
    &[data-role='fixed-origin'] {
        z-index: z-index(3);
    }
}

.navbar-grid {
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    & [data-role='grid'] {
        padding-top: 0;
        padding-bottom: 0;
    }
}

/* left children */
.close-box {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.close {
    @include font(body);
}
.close-icon {
    font-size: 1.6em;
    margin-right: 0.2em;
}
.close-text {
    transform-origin: left;
}

/* title */
.title-box {
    overflow: hidden;
}
.title,
.subtitle {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.title {
    @include font(headline);
    color: color(black);
}
.subtitle {
    @include font(caption-2);
    color: color(grey, 4);
}

/* right children */
.tools {
    text-align: right;
    padding-top: 0;
    padding-bottom: 0;
}
