@import '@/style/index.scss';

@keyframes loading-rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loading-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -40;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -120;
    }
}

.spinner {
    color: color(primary, 2);
    position: relative;
    & [data-role='loading-svg'] {
        height: 1em;
        width: 1em;
        animation: loading-rotate 2s linear infinite;
        border-radius: 50%;
    }
    & [data-role='loading-circle'] {
        animation: loading-dash 1.5s $ease-in-out infinite;
        stroke-width: 0.15em;
        stroke: currentColor;
        stroke-linecap: round;
    }
}

@keyframes bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.point-spinner {
    color: color(primary, 2);
    & > [data-role='loading-spinner_point'] {
        background-color: currentColor;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        display: inline-block;
        -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
        animation: bouncedelay 1.4s infinite ease-in-out both;

        &:nth-child(1) {
            animation-delay: -0.32s;
        }
        &:nth-child(2) {
            animation-delay: -0.16s;
        }
    }
}

@keyframes rotateZ {
    0% {
        transform: rotateZ(45deg);
    }
    100% {
        transform: rotateZ(405deg);
    }
}

@keyframes fold-cube-angle {
    0%,
    10% {
        transform: perspective(140px) rotateX(-180deg);
        opacity: 0;
    }
    25%,
    75% {
        transform: perspective(140px) rotateX(0deg);
        opacity: 1;
    }
    90%,
    100% {
        transform: perspective(140px) rotateY(180deg);
        opacity: 0;
    }
}

.block-spinner {
    color: color(primary, 2);
    display: inline-block;
    padding: 0.3em;
    & > [data-role='loading-spinner_content'] {
        width: 1em;
        height: 1em;
        transform: rotateZ(45deg);
        & > [data-role='loading-spinner_block'] {
            float: left;
            width: 50%;
            height: 50%;
            position: relative;
            transform: scale(1.1);

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: currentColor;
                animation: fold-cube-angle 2.4s infinite linear both;
                transform-origin: 100% 100%;
            }

            &:nth-child(2) {
                transform: scale(1.1) rotateZ(90deg);
                &::before {
                    animation-delay: 0.3s;
                }
            }
            &:nth-child(4) {
                transform: scale(1.1) rotateZ(180deg);
                &::before {
                    animation-delay: 0.6s;
                }
            }
            &:nth-child(3) {
                transform: scale(1.1) rotateZ(270deg);
                &::before {
                    animation-delay: 0.9s;
                }
            }
        }
    }
}

.loading {
    padding: $gap;
    text-align: center;
    & [data-role='loading-spinner'] {
        font-size: rem(16);
    }
    & [data-role='loading-text'] {
        @include font(body);
        color: color(grey, 5);
        margin-top: $gap / 2;
    }
}
.fill {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(color(grey, 1), 0.6);
    z-index: z-index(2) + 1;
}

.enter {
    opacity: 0;
    transform: scale(0);
}
.enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 400ms $ease-in, transform 400ms;
}
.exit {
    opacity: 1;
}
.exit-active {
    opacity: 0;
    transition: opacity 400ms;
}
