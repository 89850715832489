@import '@/style/index.scss';

.icon {
    font-size: rem(21);
    width: 1.7em;
    height: 1.7em;
    color: color(white);
    background-color: color(grey, 4);
    border-radius: 50%;
}

.timezone {
    font-size: 0.7em;
    color: color(grey, 7);
    background-color: color(grey, 2);
    padding: 0 rem(4);
    border-radius: $border-radius;
    vertical-align: middle;
}

.amount {
    text-align: center;
    &[data-type='-1'] {
        color: color(blue, 5);
    }
    &[data-type='0'] {
        color: color(grey, 8);
    }
    &[data-type='1'] {
        color: color(red, 5);
    }
    & > [data-float='true'] {
        font-size: 0.8em;
    }
}

.members {
    & [data-members-item] {
        width: 100%;
        padding: $gap / 2 0;
        border-bottom: 1px dashed color(grey, 2);
    }
    & [data-members-name] {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    & [data-members-icon] {
        color: color(grey, 5);
    }
}

.members-width {
    width: rem(150);
}