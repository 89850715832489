@import '@/style/index.scss';

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    & > [data-role='modal-overlay'] {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        &[data-color='black'] {
            background-color: rgba(color(black), 0.3);
        }
        &[data-color='white'] {
            background-color: rgba(color(white), 0.3);
        }
        &[data-color='transparent'] {
            background-color: transparent;
        }
    }
    & > [data-role='modal-body'] {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-overflow-scrolling: touch;
    }
}

.appear,
.enter {
    opacity: 0;
}
.appear-active,
.enter-active {
    opacity: 1;
    transition: opacity 150ms $ease-in;
}
.exit {
    opacity: 1;
}
.exit-active {
    opacity: 0;
    transition: opacity 150ms;
}
