/* prettier-ignore */
$z-index-map: (
    1:    1, // document
    2: 1000, // content
    3: 5000, // bar
    4: 10000, // modal
    5: 1000000, // notification
);

@function z-index($level) {
    @return map-get($z-index-map, $level);
}