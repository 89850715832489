@import '@/style/index.scss';

.item {
    border-bottom: 1px dashed color(grey, 2);
}

.name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}