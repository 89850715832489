@import '@/style/index.scss';

.drawer {
    & [data-role='drawer'] {
        width: rem(200);
        max-width: 100vw;
        position: relative;
    }
}

.bottom {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    padding: $gap / 2;
    padding-top: $gap * 2;
    background-image: linear-gradient(
        to bottom,
        rgba(color(white, 5), 0),
        rgba(color(white, 5), 1) $gap * 2
    );
}
