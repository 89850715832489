/* reference https://github.com/mui-org/material-ui/ */

$shadow-key-umbra-opacity: 0.15;
$shadow-key-penumbra-opacity: 0.1;
$shadow-ambient-shadow-opacity: 0.08;

@function createShadow(
    $a1,
    $a2,
    $a3,
    $a4,
    $b1,
    $b2,
    $b3,
    $b4,
    $c1,
    $c2,
    $c3,
    $c4
) {
    @return $a1 * 1px $a2 * 1px $a3 * 1px $a4 * 1px
            rgba(0, 0, 0, $shadow-key-umbra-opacity),
        $b1 * 1px $b2 * 1px $b3 * 1px $b4 * 1px
            rgba(0, 0, 0, $shadow-key-penumbra-opacity),
        $c1 * 1px $c2 * 1px $c3 * 1px $c4 * 1px
            rgba(0, 0, 0, $shadow-ambient-shadow-opacity);
}

$shadow-map: (
    0: 'none',
    1: createShadow(0, 1, 3, 0, 0, 1, 1, 0, 0, 2, 1, -1),
    2: createShadow(0, 1, 5, 0, 0, 2, 2, 0, 0, 3, 1, -2),
    3: createShadow(0, 1, 8, 0, 0, 3, 4, 0, 0, 3, 3, -2),
    4: createShadow(0, 2, 4, -1, 0, 4, 5, 0, 0, 1, 10, 0),
    5: createShadow(0, 3, 5, -1, 0, 5, 8, 0, 0, 1, 14, 0),
    6: createShadow(0, 3, 5, -1, 0, 6, 10, 0, 0, 1, 18, 0),
    7: createShadow(0, 4, 5, -2, 0, 7, 10, 1, 0, 2, 16, 1),
    8: createShadow(0, 5, 5, -3, 0, 8, 10, 1, 0, 3, 14, 2),
    9: createShadow(0, 5, 6, -3, 0, 9, 12, 1, 0, 3, 16, 2),
    10: createShadow(0, 6, 6, -3, 0, 10, 14, 1, 0, 4, 18, 3)
);

@function shadow($level) {
    @return map-get($shadow-map, $level);
}
