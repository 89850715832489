@import '@/style/index.scss';

.header {
    @include font(title-1);
}

.profile-picture {
    width: rem(60);
    height: rem(60);
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid color(grey, 2);
    padding: 0;
    & > img {
        width: 100%;
        height: 100%;
    }
    & > [data-role='icon'] {
        font-size: rem(30);
        color: color(grey, 2);
    }
}

.button-list {
    & [data-role='button'] {
        &:not(:first-child) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            margin-top: -1px;
        }
        &:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
