@font-face {
    font-family: 'iconfont'; /* project id 1619894 */
    src: url('//at.alicdn.com/t/font_1619894_dzyp2fgfzla.eot');
    src: url('//at.alicdn.com/t/font_1619894_dzyp2fgfzla.eot?#iefix')
            format('embedded-opentype'),
        url('//at.alicdn.com/t/font_1619894_dzyp2fgfzla.woff2') format('woff2'),
        url('//at.alicdn.com/t/font_1619894_dzyp2fgfzla.woff') format('woff'),
        url('//at.alicdn.com/t/font_1619894_dzyp2fgfzla.ttf') format('truetype'),
        url('//at.alicdn.com/t/font_1619894_dzyp2fgfzla.svg#iconfont')
            format('svg');
}

.icon {
    font-family: 'iconfont';
    user-select: none;
    font-size: 1.1em;
    vertical-align: -0.05em;
}
