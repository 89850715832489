@import '@/style/index.scss';

.calculator {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: color(white);
    width: rem(320);
    height: rem(560);
    max-height: 100vh;
    overflow: hidden;
    margin: 0 auto;
    border-radius: rem(12);

    & [data-role='calculator-screen'] {
        height: 40%;
    }
    & [data-role='calculator-keyboard'] {
        height: 60%;
    }

    @media #{screen-media(sm)} {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.enter,
.appear {
    opacity: 0;
    & [data-role='calculator-keyboard'] {
        transform: translateY(100%);
    }
}
.enter-active,
.appear-active {
    opacity: 1;
    transition: opacity 400ms $ease-out-quint;
    & [data-role='calculator-keyboard'] {
        transform: translateY(0);
        transition: transform 400ms $ease-out-quint;
    }
}
.exit {
    opacity: 1;
    & [data-role='calculator-keyboard'] {
        transform: translateY(0);
    }
}
.exit-active {
    opacity: 0;
    transition: opacity 400ms $ease-out-quint;
    & [data-role='calculator-keyboard'] {
        transform: translateY(100%);
        transition: transform 400ms;
    }
}
