@import '@/style/index.scss';

.keyboard {
    display: grid;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: rem(6);
    padding: rem(6);
    background-color: color(white);
    outline-color: transparent;
}

.button {
    border-radius: rem(12);

    & [data-role='icon'] {
        font-size: 1em;
    }

    &.button {
        font-size: 1.6em;
        color: color(grey, 7);
        border: 1px solid color(grey, 5);
        background-color: color(grey, 2);
    }

    &[data-role='+'],
    &[data-role='-'],
    &[data-role='*'],
    &[data-role='/'] {
        color: color(white);
        border-color: color(yellow, 7);
        background-color: color(yellow, 4);
    }

    &[data-role='reset'],
    &[data-role='backspace'] {
        background-color: color(grey, 1);
    }

    &[data-role='='] {
        color: color(white);
        border-color: color(red, 7);
        background-color: color(red, 4);
    }

    &[data-role='1'] {
        grid-area: 2 / 1;
    }

    &[data-role='2'] {
        grid-area: 2 / 2;
    }

    &[data-role='3'] {
        grid-area: 2 / 3;
    }

    &[data-role='4'] {
        grid-area: 3 / 1;
    }

    &[data-role='5'] {
        grid-area: 3 / 2;
    }

    &[data-role='6'] {
        grid-area: 3 / 3;
    }

    &[data-role='7'] {
        grid-area: 4 / 1;
    }

    &[data-role='8'] {
        grid-area: 4 / 2;
    }

    &[data-role='9'] {
        grid-area: 4 / 3;
    }

    &[data-role='0'] {
        grid-area: 5 / 1 / 5 / 3;
    }

    &[data-role='.'] {
        grid-area: 5 / 3;
    }

    &[data-role='+'] {
        grid-area: 4 / 4;
    }

    &[data-role='-'] {
        grid-area: 3 / 4;
    }

    &[data-role='*'] {
        grid-area: 2 / 4;
    }

    &[data-role='/'] {
        grid-area: 1 / 4;
    }

    &[data-role='='] {
        grid-area: 5 / 4;
    }

    &[data-role='reset'] {
        grid-area: 1 / 1;
    }

    &[data-role='backspace'] {
        grid-area: 1 / 2 / 1 / 4;
    }
}
