@font-face {
    font-family: 'classify';  /* project id 1619951 */
    src: url('//at.alicdn.com/t/font_1619951_0ve4vgvpcqzl.eot');
    src: url('//at.alicdn.com/t/font_1619951_0ve4vgvpcqzl.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1619951_0ve4vgvpcqzl.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1619951_0ve4vgvpcqzl.woff') format('woff'),
    url('//at.alicdn.com/t/font_1619951_0ve4vgvpcqzl.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1619951_0ve4vgvpcqzl.svg#classify') format('svg');
  }

.icon {
    font-family: 'classify';
    user-select: none;
}
