@import '@/style/index.scss';

.title {
    text-align: center;
    @include font(large-title);
    padding: 10vh 0;
    color: color(grey, 8);
}


.link {
    display: flex;
    width: 100%;
}