@import '@/style/index.scss';

.icon {
    @include font(bold-title);
    color: color(primary, 3);
}

.text {
    @include font(body);
    color: color(grey, 7);
}