@import '@/style/index.scss';


.record {
    background-color: color(white);
    position: relative;
    display: block;
    width: 100%;
    & + & {
        &:after {
            content: '';
            position: absolute;
            border-bottom: 1px color(grey, 2) solid;
            width: calc(100% - #{$gap * 2});
            top: 0;
            left: $gap;
            transform: scale(1, 0.5);
        }
    }
    &:hover {
        background-color: color(grey, 1);
    }
    &:active {
        background-color: color(grey, 2);
    }
}
.icon {
    margin: $gap;
}
.main {
    margin-right: $gap;
}
.time {
    @include font(footnote);
    color: color(grey, 3);
    margin-right: $gap;
    > span + span {
        margin-left: rem(4);
    }
}
.amount {
    @include font(body);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.detail {
    @include font(callout);
    color: color(grey, 5);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.currency {
    @include font(footnote);
    color: color(grey, 6);
    margin-left: $gap;
}
